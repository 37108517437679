<template>
  <div @click="backgroundClicked()" class="modal-background">
    <div @click.stop="modelClicked()" class="modal">
      <div class="w-layout-grid mail-popup-grid">
        <div id="w-node-be5d361467d7-3ed844e8" class="full-name-container">
          <div class="full-name-text">
            <v-text-field
              name="fullName"
              label="Full Name"
              placeholder="Full Name"
              solo
              v-model="fullName"
            ></v-text-field>
          </div>
        </div>
        <div id="w-node-a8ab9d8f2b1e-3ed844e8" class="full-name-container">
          <div class="email-container">
            <v-text-field
              name="email"
              label="Email Address"
              placeholder="Email Address"
              solo
              v-model="emailAddress"
            ></v-text-field>
          </div>
        </div>
        <div id="w-node-765e96bd6fbf-3ed844e8" class="full-name-container auto">
          <div class="mobile-number-text">
            <v-text-field
              name="contactNumber"
              label="Contact Number (optional)"
              placeholder="Contact Number (optional)"
              solo
              v-model="contactNumber"
            ></v-text-field>
          </div>
        </div>
        <div class="checkbox-container">
          <div class="checkbox-item-container">
            <v-checkbox
              v-model="receiveInfo"
              label=""
              color="primary"
              value=""
              hide-details
            ></v-checkbox>
          </div>
          <div class="checkbox-text-container">
            <div class="checkbox-text">
              I would like to receive information about future events and
              activities of Vedanta Spain
            </div>
          </div>
        </div>
        <div @click.stop="getInvited()"
          class="submit-button-container cursorLinkPointer"
        >
          <div class="submit-text">
              <v-btn
                @click.stop="getInvited()"
                elevation="0"
                :loading="loading"
                :ripple="false"
                class="primary createEventBtn"
                >GET INVITED</v-btn
              >
          </div>
        </div>
        <div class="privacy-policy-container">
          <div
            @click="$router.openNewTab('/privacy-policy')"
            class="privacy-popup-text"
          >
            * By submitting this form you confirm that you are aware of our
            <span class="text-span">Privacy Policy</span>.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "EventMailDialog",
  data() {
    return {
      fullName: "",
      emailAddress: "",
      contactNumber: "",

      receiveInfo: false,
      errorText: "",
    };
  },
  computed: {
      loading() {
          return this.$store.getters.loading
      },
    eventDisplayed() {
      return this.$store.getters.eventDisplayed;
    },
    eventMailDialog: {
      get() {
        return this.$store.getters.eventMailDialog;
      },
      set(value) {
        this.$store.dispatch("setEventMailDialog", value);
      },
    },
  },
  methods: {
      
    getInvited() {
      if (this.checkForm()) {
        console.log("Error found");
        this.$store.dispatch("showErrorDialog", {
          title: "Missing Fields",
          text: this.errorText
        });
       /*  this.$store.dispatch("showSnackBar", {
          text: this.errorText,
          color: "red",
          timeout: 0,
        }); */
      } else {
        this.sendMail();
      }
    },
    checkForm() {
      var errorFound = false;
      var firstParam = true;
      var errorText = "Enter the following: ";
      if (this.fullName === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Full Name";
        errorFound = true;
      }

      if (this.emailAddress === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Email Address";
        errorFound = true;
      }

      if (!errorFound && !this.receiveInfo) {
        errorText = "Tick the checkbox to get future updates";
        errorFound = true;
      }
      console.log("Error: ", errorText);
      this.errorText = errorText;
      return errorFound;
    },
    async sendMail() {
      var subject = `Requesting Invitation - ${this.eventDisplayed.name}`;
      var toEmail = "info@vedantaspain.com"
      if (this.eventDisplayed.whatsappPerson.name === "Rishanth") {
          toEmail = "rh@vedantaspain.com"
      } else {
          toEmail = "kl@vedantaspain.com"
      }
      var fromEmail = "vedanta-mailer@cloudcerebro.com";

      var body = `<h3>Invitation Request</h3>
            <table>
            <tr>
                <td valign="top">
                  <b>Full Name</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.fullName}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Email Address</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.emailAddress}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Contact Number</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.contactNumber}
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td valign="top">
                  <b>Event Name</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.eventDisplayed.name}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Date</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.getFormattedDate()}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Time</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.getFormattedTime()}
                </td>
              </tr>
            </table>`;

      var mail = {
        fromEmail: fromEmail,
        toEmail: toEmail,
        subject: subject,
        body: body,
      };
      console.log("Enquiry details: ", mail);
      await this.$store.dispatch("sendDetailsByEmail", mail);
      this.$store.dispatch("showSnackBar", {
          text: "Email sent. Please wait till acceptance",
          color: "green",
          timeout: 4000,
        });
        this.eventMailDialog = false;
    },
    resetForm() {
      this.fullName = ''
      this.emailAddress = ''
      this.contactNumber = ''
      this.errorText = ''
      this.receiveInfo = false
    },
    getFormattedDate() {
      console.log("Event: ", this.eventDisplayed);
      var date = this.eventDisplayed.fromDate;
      console.log("Date to be formatted: ", date);
      var newDate;
      if (date) {
        newDate = moment(date, "DD/MM/YYYY").format("dddd, DD MMMM YYYY");
      }

      return newDate;
    },
    getFormattedTime() {
      console.log("Date to be formatted: ", this.eventDisplayed);
      var fromTime = this.eventDisplayed.fromTime;
      var toTime = this.eventDisplayed.toTime;
      var newDuration;
      if (fromTime && toTime) {
        newDuration = `${fromTime} to ${toTime} (CET)`;
      }

      return newDuration;
    },
    backgroundClicked() {
      console.log("Background clicked");
      this.$store.dispatch("setEventMailDialog", false);
    },
    modelClicked() {
      console.log("Model clicked");
    },
  },
};
</script>

<style>
.email-container .v-input__slot,
.mobile-number-text .v-input__slot,
.full-name-text .v-input__slot {
  min-height: 30px !important;
  box-shadow: none !important;
  background: #ffffff00 !important;
}
.email-container .v-input__control,
.mobile-number-text .v-input__control,
.full-name-text .v-input__control {
  min-height: 30px !important;
  height: 30px !important;
}
.email-container .v-text-field__details,
.mobile-number-text .v-text-field__details,
.full-name-text .v-text-field__details {
  display: none !important;
}
.email-container input,
.mobile-number-text input,
.full-name-text input {
  font-size: 14px !important;
  color: black !important;
}
.email-container,
.mobile-number-text,
.full-name-text {
  padding: 4px 4px !important;
}
.checkbox-item-container .v-input--selection-controls {
  margin-top: 0px !important;
}
.createEventBtn:focus::before {
  opacity: 0 !important;
}
.createEventBtn::before {
    color: transparent
}

.createEventBtn:hover {
    color: red;
}

</style>