<template>
  <div>
      <v-card  class="rounded-xl">
      <v-responsive height="100%" ref="studyClassesResponsiveContainer">
        <v-card-text>
          <calendar-component :calendarStyle="calendarStyle" :admin="false" :key="calendarKey" :events="calendarEvents" />
        </v-card-text>
      </v-responsive>
    </v-card>
     
  </div>
</template>

<script>
import CalendarComponent from "./CalendarComponent"
export default {
name: "Home",
  components: {
    CalendarComponent
  },
  data() {
    return {
     
    };
  },
  async mounted() {
    await this.$store.dispatch("listAllEvents");
    this.getHeight()
    console.log("Compoent width: ", window.innerWidth)
  },
  computed: {
    calendarStyle() {
      var calendarHeight = 460;
      if (window.innerWidth > 991) {
        calendarHeight = 364;
      } else {
        calendarHeight = 304;
      }
      console.log("Changed")
      return `height: ${calendarHeight}px`
    },
    calendarEvents() {
      return this.$store.getters.allEvents;
    },
    calendarKey() {
      return this.$store.getters.calendarKey;
    }
  },
  methods: {
    getHeight() {
      console.log('Height: ', this.$refs.studyClassesResponsiveContainer.$el.clientHeight)
      return this.$refs.studyClassesResponsiveContainer.$el.clientHeight
    }
  }

  


};
</script>

<style>

</style>