<template>
  <div class="">
    <div @click="backgroundClicked()" class="modal-background">
      <div @click.stop="modelClicked()" class="modal-with-badge">
        <div id="w-node-148ba9a298c2-5dd0440b" class="badge">
            <div class="badge-text">{{ getFormattedBadge() }}</div>
          </div>
          <div class="modal">
            <div class="w-layout-grid grid-2">
          <div id="w-node-c93d71c3406e-5dd0440b" class="event-name-container">
            <div class="event-name-text">{{ eventDisplayed.name }}</div>
            <div v-if="isDescriptionAvailable()" id="event-description" class="event-description">
              <v-textarea
                    class="eventDescriptionTextArea"
                    solo
                    background-color="transparent"
                    readonly
                    no-resize
                    auto-grow
                    name="input-7-4"
                    label="Venue"
                    placeholder="Venue"
                    v-model="selectedEventDescription"
                    rows="1"
                  ></v-textarea>
            </div>
          </div>
          
          <div
            id="w-node-0391d36dbab0-5dd0440b"
            class="event-details-container"
          >
            <div class="date-container">
              <div class="icon-container">
                <img
                  src="images/calendar-popup.svg"
                  loading="lazy"
                  alt=""
                  class="popup-icon calendar-icon"
                />
              </div>
              <div class="event-date-container">
                <div class="event-date-text">{{ getFormattedDate() }}</div>
              </div>
            </div>
            <div class="date-container">
              <div class="icon-container">
                <img
                  src="images/clock-popup.svg"
                  loading="lazy"
                  alt=""
                  class="popup-icon time-icon"
                />
              </div>
              <div class="event-date-container">
                <div class="event-date-text">
                  {{ getFormattedTime() }}
                </div>
              </div>
            </div>
            <div v-if="isVenueAvailable()" class="venue-container">
              <div class="icon-container">
                <img
                  src="images/venue-popup.svg"
                  loading="lazy"
                  alt=""
                  class="popup-icon venue-icon"
                />
              </div>
              <div class="event-venue-container">
                <div v-if="isVenueAvailable()" class="venue-text">
                  <v-textarea
                    class="venueDisplayTextArea"
                    solo
                    background-color="transparent"
                    readonly
                    no-resize
                    auto-grow
                    name="input-7-4"
                    label="Venue"
                    placeholder="Venue"
                    v-model="selectedEventVenue"
                    rows="1"
                  ></v-textarea>
                </div>
                <div v-if="!isVenueAvailable()" class="venue-text">
                  {{ getFormattedVenue() }}
                </div>
              </div>
            </div>
            <div v-if="(eventDisplayed.registrationLink != null || eventDisplayed.registrationLink != undefined)" class="reg-container">
              <div class="icon-container"><img src="images/add-1.svg" loading="lazy" alt="" class="popup-icon venue-icon"></div>
              <div class="event-registration-container">
                <a
                      @click="$router.absUrl(formattedRegistrationLink())"
                      class="event-registration-link"
                      >Click here to register</a
                    >
              </div>
            </div>
            <div v-if="eventDisplayed.meetingLink != null && eventDisplayed.meetingID != null" class="zoom-container">
              <div class="icon-container">
                <img
                  src="images/zoom-ico-popup.svg"
                  loading="lazy"
                  alt=""
                  class="popup-icon zoom-popup"
                />
              </div>
              <div class="event-zoom-container">
                <div class="zoom-meeting-id-container">
                  <div v-if="eventDisplayed.meetingID != null"  class="meeting-id-container">
                    <div class="meeting-id-header-container">
                      <div class="meeting-id-text">Meeting ID :</div>
                    </div>
                    <div class="meeting-id-content-container">
                      <div class="zoom-meeting-id-text">
                        {{ formattedMeetingID() }}
                      </div>
                    </div>
                  </div>
                  <div v-if="eventDisplayed.meetingLink != null" class="zoom-meeting-link-container">
                    <a
                      @click="$router.absUrl(formattedMeetingLink())"
                      class="zoom-link"
                      >Click here to join</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-number-container">
              <div class="icon-container">
                <img
                  src="images/phone-popup.svg"
                  loading="lazy"
                  alt=""
                  class="popup-icon phone-popup"
                />
              </div>
              <div class="event-contact-container">
                <a
                  href="tel:+34610984993"
                  class="rishanth-container w-inline-block"
                >
                  <div class="rishanth-contact">Rishanth (+34 610 984 993)</div>
                </a>
                <a
                  href="tel:+34697251222"
                  class="kat-container w-inline-block"
                >
                  <div class="kat-contact">Katharina (+34 697 251 222)</div>
                </a>
              </div>
            </div>
          </div>
          <div id="w-node-dcc694f5f931-5dd0440b" class="event-pic-container">
            <v-responsive>
              <v-img
                @click="showImage(eventDisplayed.imageUrl)"
                contain
                :src="eventDisplayed.imageUrl"
                loading="lazy"
                alt=""
                class="image-2"
                height="200px"
                width="200px"
              >
              </v-img>
            </v-responsive>
          </div>
          <div
            @click="sendWhatsapp()"
            id="w-node-101e1b4f5060-5dd0440b"
            class="whatsapp-button-container cursorLinkPointer"
          >
            <div class="icon-container">
              <img
                src="images/logos_whatsapp-1.svg"
                loading="lazy"
                alt=""
                class="whatsapp"
              />
            </div>
            <div class="whatsapp-button-text">
              <div class="button-text whatsapp">GET INVITED</div>
            </div>
            <div class="button-arrow-container">
              <lottie-animation
                class="lottie-animation-3"
                :animation="ButtonArrowAnimation"
                :animationSpeed="1.5"
              ></lottie-animation>
            </div>
          </div>
          <div
            @click="eventMailDialog = true"
            id="w-node-d697e0662dbd-5dd0440b"
            class="cursorLinkPointer mail-button-container"
          >
            <div class="icon-container">
              <img
                src="images/email.svg"
                loading="lazy"
                alt=""
                class="whatsapp"
              />
            </div>
            <div class="whatsapp-button-text">
              <div class="button-text mail">GET INVITED</div>
            </div>
            <div class="button-arrow-container">
              <lottie-animation
                class="lottie-animation-3"
                :animation="ButtonArrowAnimation"
                :animationSpeed="1.5"
              ></lottie-animation>
            </div>
          </div>
        </div>
          </div>
        
      </div>
    </div>
    <v-dialog v-model="eventMailDialog">
      <event-mail-dialog />
    </v-dialog>
    <v-dialog v-model="showImageDialog">
      <v-card>
        <v-img contain height="80vh" :src="imageDialogUrl"> </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import ButtonArrow from "../../assets/LottieAnimations/lottieflow-play-03-ffffff-easey.json";
import LottieAnimation from "../../components/LottieAnimationComponent";
import EventMailDialog from "./EventMailDialog.vue";
export default {
  name: "EventDialog",
  components: {
    LottieAnimation,
    EventMailDialog,
  },
  data() {
    return {
      ButtonArrowAnimation: ButtonArrow,
      showImageDialog: false,
      imageDialogUrl: "",
    };
  },
  mounted() {
    console.log("Passed event: ", this.eventDisplayed);
  },
  computed: {
    selectedEventVenue() {
      return JSON.parse(this.getFormattedVenue());
    },
    selectedEventDescription() {
      
        return JSON.parse(this.getFormattedDescription());
    },
    eventDisplayed() {
      return this.$store.getters.eventDisplayed;
    },
    eventMailDialog: {
      get() {
        return this.$store.getters.eventMailDialog;
      },
      set(value) {
        this.$store.dispatch("setEventMailDialog", value);
      },
    },
  },
  methods: {
    sendWhatsapp() {
      var phone;
      if (this.eventDisplayed.whatsappPerson.name === "Rishanth") {
        phone = "+34610984993";
      } else {
        phone = "+34697251222";
      }
      var body = `Hi, I would like to join this event: %0a*Event Name:* ${
        this.eventDisplayed.name
      } %0a*Event Date:* ${this.getFormattedDate()} %0a*Event Time:* ${this.getFormattedTime()}`;
      window.open(
        "https://api.whatsapp.com/send?phone=" +
          phone +
          "&text=" +
          body +
          "&lang=en"
      );
    },
    showImage(imageUrl) {
      this.imageDialogUrl = imageUrl;
      this.showImageDialog = true;
    },
    isRishanth() {
      var whatsappPerson = this.eventDisplayed.whatsappPerson;
      if (whatsappPerson.name === "Rishanth") {
        return true;
      } else {
        return false;
      }
    },
    isKatharina() {
      var whatsappPerson = this.eventDisplayed.whatsappPerson;
      if (whatsappPerson.name === "Katharina") {
        return true;
      } else {
        return false;
      }
    },
    getFormattedBadge() {
      var badge = this.eventDisplayed.type;
      var newBadge;
      if (badge) {
        newBadge = badge;
        if (badge === "both") {
          newBadge = "Online & Offline";
        } else if (badge === "online") {
          newBadge = "Online";
        } else {
          newBadge = "Offline";
        }
      }
      return newBadge;
    },
    getFormattedDate() {
      console.log("Event: ", this.eventDisplayed);
      var date = this.eventDisplayed.fromDate;
      console.log("Date to be formatted: ", date);
      var newDate;
      if (date) {
        newDate = moment(date, "DD/MM/YYYY").format("dddd, DD MMMM YYYY");
      }

      return newDate;
    },
    getFormattedTime() {
      console.log("Date to be formatted: ", this.eventDisplayed);
      var fromTime = this.eventDisplayed.fromTime;
      var toTime = this.eventDisplayed.toTime;
      var newDuration;
      if (fromTime && toTime) {
        newDuration = `${fromTime} to ${toTime} (CET)`;
      }

      return newDuration;
    },
    isDescriptionAvailable() {
      var description = this.eventDisplayed.description;
      if (description === null || description === undefined) {
        return false;
      } else {
        return true;
      }
    },
    isVenueAvailable() {
      var venue = this.eventDisplayed.venue;
      if (venue === null) {
        return false;
      } else {
        return true;
      }
    },
    getFormattedDescription() {
      console.log('Getting formatted description: ', this.eventDisplayed.description)
      var description = this.eventDisplayed.description;
      var newDescription;
      if (description === null || this.eventDisplayed.description === undefined) {
        newDescription = "NA";
      } else {
        newDescription = description;
      }
      console.log("New Descriptin:", newDescription);
      
      
      return newDescription;
    },
    getFormattedVenue() {
      console.log('Getting formatted venue: ', this.eventDisplayed.venue)
      var venue = this.eventDisplayed.venue;
      var newVenue;
      if (venue === null) {
        newVenue = "NA";
      } else {
        newVenue = venue;
      }
      return newVenue;
    },
    formattedRegistrationLink() {
      var link = this.eventDisplayed.registrationLink;
      var newLink = "NA";
      if (link != null) {

        if (link.startsWith("https://")) {
          newLink = link;
      } else {
        newLink = "https://" + link;
      }
      }
      return newLink;
    },
    formattedMeetingLink() {
      var link = this.eventDisplayed.meetingLink;
      var newLink = "NA";
      if (link != null) {

        if (link.startsWith("https://")) {
          newLink = link;
      } else {
        newLink = "https://" + link;
      }
      }
      return newLink;
    },
    formattedMeetingID() {
      var meetingID = this.eventDisplayed.meetingID;
      var newMeetingID = "NA";
      if (meetingID != null) {

      meetingID.replace(/\s/g, "");
      console.log("Trimmed meetingID: ", meetingID);
      newMeetingID =
        meetingID.substr(0, 3) +
        " " +
        meetingID.substr(3, 4) +
        " " +
        meetingID.substr(7, 4) +
        " " +
        meetingID.substr(11, 4);
      }
      return newMeetingID;
    },
    backgroundClicked() {
      console.log("Background clicked");
      this.$store.dispatch("setEventDialog", false);
    },
    modelClicked() {
      console.log("Model clicked");
    },
    async sendMailDemo() {
      var subject = `Requesting Invitation - ${this.eventDisplayed.name}`;
      var toEmail = "mapkbalaji@gmail.com";
      var fromEmail = "vedanta-mailer@cloudcerebro.com";

      var body = `<h3>New Room Booking</h3>
            <table>
              <tr>
                <td valign="top">
                  <b>Name</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.eventDisplayed.name}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Date</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.getFormattedDate()}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Time</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.getFormattedTime()}
                </td>
              </tr>
            </table>`;

      var mail = {
        fromEmail: fromEmail,
        toEmail: toEmail,
        subject: subject,
        body: body,
      };
      console.log("Enquiry details: ", mail);
      await this.$store.dispatch("sendDetailsByEmail", mail);
    },
  },
};
</script>

<style>
.venueDisplayTextArea .v-text-field__details,
.eventDescriptionTextArea .v-text-field__details {
  display: none !important;
}
.venueDisplayTextArea .v-input__slot,
.eventDescriptionTextArea .v-input__slot {
  box-shadow: none !important;
  padding: 0px !important;
}
.venueDisplayTextArea textarea {
  margin-top: 0px !important;
}
.eventDescriptionTextArea textarea {
  margin-top: 0px !important;
  font-size: 14px;
  line-height: 1rem !important;
}
@media screen and (max-width: 767px) {
.venueDisplayTextArea textarea,
.eventDescriptionTextArea textarea {
  font-size: 14px;
  line-height: 1.5;
}
}

</style>