<template>
  <div class="activities">
    <div class="inspiration-section activities">
      <div class="inspiration-container">
        <div class="w-layout-grid activities-grid resetMargin">
          <div
            id="w-node-65252c0b3474-9e4ccf6c"
            class="inspiration-sticky-menu activities"
          >
            <div class="sticky-menu-container">
              <div class="sticky-menu-header activities"></div>
              <div
                class="inspiration-sticky-items-container without-header activities"
              >
                <div class="inspiration-sticky-items-container activities">
                  <a
                    v-scroll-to="{
                      el: '.calendarContainer',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    :class="calendarScrolledIntoView ? 'current' : ''"
                    class="inspiration-sticky-item activities"
                  >
                    <div class="inspiration-sticky-item">CALENDAR</div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '.studyClassesContainer',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    :class="studyClassesScrolledIntoView ? 'current' : ''"
                    class="inspiration-sticky-item activities"
                  >
                    <div class="inspiration-sticky-item">STUDY CLASSES</div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '.monthlyTalksContainer',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    :class="monthlyTalksScrolledIntoView ? 'current' : ''"
                    class="inspiration-sticky-item activities"
                  >
                    <div class="inspiration-sticky-item">
                      INTRODUCTORY TALKS
                    </div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '.workshopsContainer',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    :class="workshopsScrolledIntoView ? 'current' : ''"
                    class="inspiration-sticky-item activities"
                  >
                    <div class="inspiration-sticky-item">WORKSHOPS</div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '.retreatsContainer',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    :class="retreatsScrolledIntoView ? 'current' : ''"
                    class="inspiration-sticky-item activities"
                  >
                    <div class="inspiration-sticky-item">RETREATS</div>
                  </a>
                  <a
                    v-scroll-to="{
                      el: '.corporateActivitiesContainer',
                      offset: -120,
                      easing: [0.6, 0.8, 0.5, 1.1],
                      duration: 700,
                    }"
                    :class="
                      corporateActivitiesScrolledIntoView ? 'current' : ''
                    "
                    class="inspiration-sticky-item activities"
                  >
                    <div class="inspiration-sticky-item">
                      CORPORATE ACTIVITIES
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewCalendarHandler"
            class="calendarContainer side-grid-container study-classes no-margin-on-top w-node-65252c0b3485-9e4ccf6c"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">Calendar</div>
            </div>
            <div class="content-text-container inspiration study-classes">
              <div class="content-text center-aligned activities">
                There are various events conducted by Vedanta Spain. Please
                scroll through the calendar to find the class or event which is
                suitable for you. And click on it for further details and
                registration.
              </div>
            </div>
            <div class="study-classes-calendar-container">
              <study-classes-calendar />
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewStudyClassesHandler"
            class="studyClassesContainer side-grid-container study-classes monthly-talks w-node-6490e842c9da-9e4ccf6c"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">
                Study Classes
              </div>
            </div>
            <div class="content-text-container inspiration study-classes">
              <div class="content-text center-aligned activities">
                The Vedanta Study classes are conducted weekly at different
                venues. Regular classes on Vedanta help develop clearer thinking
                to adopt a more peaceful and productive lifestyle. We study and
                discuss the books written by A. Parthasarathy
              </div>
            </div>
            <!--   <div class="study-classes-calendar-container monthly-talks">
            </div> -->
            <v-row>
              <v-col>
                <v-card class="rounded-xl">
                  <v-img :src="studyClassesImageUrl" contain></v-img>
                </v-card>
              </v-col>
            </v-row>
            <div
              @click="$router.push('/contact-us')"
              class="button-container activities"
            >
              <div class="button-text-2">
                <div class="button-text-2">CONTACT US</div>
              </div>
              <div class="button-arrow">
                <lottie-animation
                  :animation="ButtonArrowAnimation"
                  :animationSpeed="1.5"
                ></lottie-animation>
              </div>
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewMonthlyTalksHandler"
            class="monthlyTalksContainer side-grid-container study-classes monthly-talks w-node-43ae238b12fc-9e4ccf6c"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">
                Introductory Talks
              </div>
            </div>
            <div class="content-text-container inspiration study-classes">
              <div class="content-text center-aligned activities">
                Vedanta Spain conducts public lectures periodically. These talks
                introduce the subject and help one understand the practical
                application of the Vedantic knowledge in life.
              </div>
            </div>
            <!--   <div class="study-classes-calendar-container monthly-talks">
            </div> -->
            <v-row>
              <v-col>
                <v-card class="rounded-xl">
                  <v-img :src="monthlyTalksImageUrl" contain></v-img>
                </v-card>
              </v-col>
            </v-row>
            <div
              @click="$router.push('/contact-us')"
              class="button-container activities"
            >
              <div class="button-text-2">
                <div class="button-text-2">CONTACT US</div>
              </div>
              <div class="button-arrow">
                <lottie-animation
                  :animation="ButtonArrowAnimation"
                  :animationSpeed="1.5"
                ></lottie-animation>
              </div>
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewWorkshopsHandler"
            class="workshopsContainer side-grid-container study-classes workshops w-node-a48ce85c8c07-9e4ccf6c"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">Workshops</div>
            </div>
            <div class="content-text-container inspiration study-classes">
              <div class="content-text center-aligned activities workshops">
                Workshops are a series of talks designed to convey a theme -
                e.g. critical thinking, progressive lifestyle, etc. - that can
                be implemented in life using this knowledge. A sample module is
                given below. If you are interested in hosting a workshop, please
                contact us to know more. The theme can be modified according to
                the requirement.
              </div>
              <img
                src="images/vedanta-sample-workshop.jpeg"
                loading="lazy"
                srcset="
                  images/vedanta-sample-workshop-p-500.jpeg 500w,
                  images/vedanta-sample-workshop.jpeg       720w
                "
                sizes="(max-width: 479px) 92vw, 360px"
                alt=""
                class="vedanta-sample-workshop"
              />
            </div>
            <div
              @click="$router.push('/contact-us')"
              class="button-container activities"
            >
              <div class="button-text-2">
                <div class="button-text-2">CONTACT US</div>
              </div>
              <div class="button-arrow">
                <lottie-animation
                  :animation="ButtonArrowAnimation"
                  :animationSpeed="1.5"
                ></lottie-animation>
              </div>
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewRetreatsHandler"
            class="retreatsContainer side-grid-container study-classes workshops retreats w-node-3eeff30438b6-9e4ccf6c"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">Retreats</div>
            </div>
            <div class="content-text-container inspiration study-classes">
              <div class="content-text center-aligned activities workshops">
                A retreat is a set of activities formulated in a schedule over a
                few days. It includes lectures, group discussions, yoga, etc.
                This gives an idea of the Vedantic life style and how it can be
                integrated into your life. Please contact us to find out more.
              </div>
            </div>
            <div
              @click="$router.push('/contact-us')"
              class="button-container activities"
            >
              <div class="button-text-2">
                <div class="button-text-2">CONTACT US</div>
              </div>
              <div class="button-arrow">
                <lottie-animation
                  :animation="ButtonArrowAnimation"
                  :animationSpeed="1.5"
                ></lottie-animation>
              </div>
            </div>
          </div>
          <div
            id="vedanta-academy"
            v-view="viewCorporateActivitiesHandler"
            class="corporateActivitiesContainer side-grid-container study-classes workshops w-node-2529ea1c9d86-9e4ccf6c"
          >
            <div class="title-text-container">
              <div class="title-text center-aligned inspiration">
                Corporate Activities
              </div>
            </div>
            <div class="content-text-container inspiration study-classes">
              <div class="content-text center-aligned activities workshops">
                The corporate world is infested with stress, strain and anxiety,
                reducing the quality of work and productivity. We offer
                workshops and lectures to find the source of these problems, how
                to tackle them and achieve success. Please contact us to find
                out more. These events can be tailored according to the
                company’s need. <br />
              </div>
            </div>
            <div
              @click="$router.push('/contact-us')"
              class="button-container activities"
            >
              <div class="button-text-2">
                <div class="button-text-2">CONTACT US</div>
              </div>
              <div class="button-arrow">
                <lottie-animation
                  :animation="ButtonArrowAnimation"
                  :animationSpeed="1.5"
                ></lottie-animation>
              </div>
            </div>
          </div>
          <div
            id="w-node-15e2c8bec7f4-9e4ccf6c"
            class="inspiration-mobile-sticky-header-3"
          >
            <div
              @click="showMenuDropDown = !showMenuDropDown"
              data-hover=""
              data-delay="200"
              class="dropdown w-dropdown cursorLinkPointer"
            >
              <div class="dropdown-toggle w-dropdown-toggle">
                <div class="title-text-container-2 inspiration">
                  <div
                    class="title-text center-aligned inspiration mobile-menu"
                  >
                    Activities
                  </div>
                </div>
                <div class="dropdown-arrow-3">
                  <img
                    src="images/vedanta-spain-arrow-down.svg"
                    loading="lazy"
                    alt=""
                    class="image-3"
                  />
                </div>
              </div>
              <nav
                class="dropdown-list inspiration-mobile w-dropdown-list"
                :class="showMenuDropDown ? 'w--open' : ''"
              >
                <a
                  v-scroll-to="{
                    el: '.calendarContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >CALENDAR</a
                >
                <a
                  v-scroll-to="{
                    el: '.studyClassesContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >STUDY CLASSES</a
                >
                <a
                  v-scroll-to="{
                    el: '.monthlyTalksContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >INTRODUCTORY TALKS</a
                >
                <a
                  v-scroll-to="{
                    el: '.workshopsContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >WORKSHOPS</a
                >
                <a
                  v-scroll-to="{
                    el: '.retreatsContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >RETREATS</a
                >
                <a
                  v-scroll-to="{
                    el: '.corporateActivitiesContainer',
                    offset: -180,
                    easing: [0.6, 0.8, 0.5, 1.1],
                    duration: 700,
                  }"
                  class="inspiration-mobile-dropdown-item-2 w-dropdown-link"
                  >CORPORATE ACTIVITIES</a
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ButtonArrow from "../assets/LottieAnimations/lottieflow-play-03-ffffff-easey.json";
import DropDownArrow from "../assets/LottieAnimations/vedanta-dropdown-arrow.json";
import LottieAnimation from "../components/LottieAnimationComponent";
import StudyClassesCalendar from "../components/Events/StudyClassesCalendar";

export default {
  name: "Activities",
  components: {
    LottieAnimation,
    StudyClassesCalendar,
  },
  data() {
    return {
      ButtonArrowAnimation: ButtonArrow,
      DropDownArrowAnimation: DropDownArrow,
      calendarScrolledIntoView: true,
      studyClassesScrolledIntoView: false,
      monthlyTalksScrolledIntoView: false,
      workshopsScrolledIntoView: false,
      retreatsScrolledIntoView: false,
      corporateActivitiesScrolledIntoView: false,
      showMenuDropDown: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    monthlyTalksImageUrl() {
      return this.$store.getters.monthlyTalksImageUrl;
    },
    studyClassesImageUrl() {
      return this.$store.getters.studyClassesImageUrl;
    },
  },
  methods: {
    viewCalendarHandler(e) {
      if (e.type === "exit") return;
      if (e.percentInView >= 0.5) {
        this.calendarScrolledIntoView = true;
        this.studyClassesScrolledIntoView = false;
        this.monthlyTalksScrolledIntoView = false;
        this.workshopsScrolledIntoView = false;
        this.retreatsScrolledIntoView = false;
        this.corporateActivitiesScrolledIntoView = false;
      }
      /* if (e.percentInView < 0.5) {
        this.calendarScrolledIntoView = false;
      } */
    },
    viewStudyClassesHandler(e) {
      if (e.type === "exit") return;
      if (e.percentInView >= 0.5) {
        this.calendarScrolledIntoView = false;
        this.studyClassesScrolledIntoView = true;
        this.monthlyTalksScrolledIntoView = false;
        this.workshopsScrolledIntoView = false;
        this.retreatsScrolledIntoView = false;
        this.corporateActivitiesScrolledIntoView = false;
      }
      /* if (e.percentInView < 0.5) {
        this.studyClassesScrolledIntoView = false;
      } */
    },
    viewMonthlyTalksHandler(e) {
      if (e.type === "exit") return;
      if (e.percentInView >= 0.5) {
        this.calendarScrolledIntoView = false;
        this.studyClassesScrolledIntoView = false;
        this.monthlyTalksScrolledIntoView = true;
        this.workshopsScrolledIntoView = false;
        this.retreatsScrolledIntoView = false;
        this.corporateActivitiesScrolledIntoView = false;
      }
      /* if (e.percentInView < 0.5) {
        this.monthlyTalksScrolledIntoView = false;
      } */
    },
    viewWorkshopsHandler(e) {
      if (e.type === "exit") return;
      if (e.percentInView >= 0.5) {
        this.calendarScrolledIntoView = false;
        this.studyClassesScrolledIntoView = false;
        this.monthlyTalksScrolledIntoView = false;
        this.workshopsScrolledIntoView = true;
        this.retreatsScrolledIntoView = false;
        this.corporateActivitiesScrolledIntoView = false;
      }
     /*  if (e.percentInView < 0.5) {
        this.workshopsScrolledIntoView = false;
      } */
    },
    viewRetreatsHandler(e) {
      if (e.type === "exit") return;
      if (e.percentInView >= 0.5) {
        this.calendarScrolledIntoView = false;
        this.studyClassesScrolledIntoView = false;
        this.monthlyTalksScrolledIntoView = false;
        this.workshopsScrolledIntoView = false;
        this.retreatsScrolledIntoView = true;
        this.corporateActivitiesScrolledIntoView = false;
      }
      /* if (e.percentInView < 0.5) {
        this.retreatsScrolledIntoView = false;
      } */
    },
    viewCorporateActivitiesHandler(e) {
      if (e.type === "exit") return;
      if (e.percentInView >= 0.5) {
        this.calendarScrolledIntoView = false;
        this.studyClassesScrolledIntoView = false;
        this.monthlyTalksScrolledIntoView = false;
        this.workshopsScrolledIntoView = false;
        this.retreatsScrolledIntoView = false;
        this.corporateActivitiesScrolledIntoView = true;
      }
      /* if (e.percentInView < 0.5) {
        this.corporateActivitiesScrolledIntoView = false;
      } */
    },
  },
};
</script>
<style>
.inspiration-sticky-item.activities {
  height: 68px !important;
}
.study-classes-calendar-container {
  background-color: transparent !important;
}
.resetMargin {
  margin-top: 30px !important;
}
@media screen and (max-width: 479px) {
  .resetMargin {
    margin-top: 20px !important;
  }
}
.inspiration-mobile a {
  color: white !important;
}
</style>