<template>
  <div>
    <v-row class="justify-center">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Today
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-col class="text-center" cols="12" :style="calendarStyle">
        <v-calendar
          ref="calendar"
          v-model="value"
          locale="en"
          color="primary"
          :type="calendarDaysDisplay"
          :events="events"
          :event-color="getEventColor"
          :event-ripple="false"
          first-time="07:00"
          interval-count="16"
          @change="getEvents"
          @click:event="eventClicked"
          :weekdays="weekday"
          :interval-format="intervalFormat"
        >
          <template v-slot:day-label-header="{ day }">
            <div>
              <strong
                ><span v-if="isToday(day)" style="color: #000000">{{
                  day
                }}</span
                ><span v-if="!isToday(day)" style="color: #000000">{{ day }}</span></strong
              >
            </div>
          </template>
          
          <template v-slot:event="{ event }">
            <div class="v-event-draggable">
              <strong>{{ event.name }}</strong
              ><br />
              {{ formatEventTime(event.start) }} <br /> to <br />
              {{ formatEventTime(event.end) }}
            </div>
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-card-title>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title>
                  <div class="text-h6">{{ selectedEvent.name }}</div>
                  <div class="text-body-1">
                    {{ getFormattedDate(selectedEvent.fromDate) }}
                  </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog v-model="deleteConfirmdialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="error" dark icon v-bind="attrs" v-on="on">
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>
                      Confirm delete
                    </v-card-title>

                    <v-card-text> Are you sure? </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="deleteConfirmdialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="deleteEvent(selectedEvent.id)"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- <v-btn @click="deleteEvent(selectedEvent.id)" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn> -->
              </v-toolbar></v-card-title
            >

            <v-card-text>
              <v-avatar class="ma-0" size="125" tile>
                <v-img
                  @click="showImage(selectedEvent.imageUrl)"
                  :src="selectedEvent.imageUrl"
                ></v-img>
              </v-avatar>
              <v-row>
                <v-col>
                  <div class="text-body-1">
                    Scheduled between <b> {{ selectedEvent.fromTime }} </b> and
                    <b>{{ selectedEvent.toTime }} CET </b>
                  </div>
                  <div v-if="selectedEvent.venue" class="text-body-1">
                    <b>Venue: </b>{{ selectedEvent.venue }}
                  </div>
                  <div class="text-body-1">
                    <b>Contact Person: </b
                    >{{
                      selectedEvent.whatsappPerson
                        ? selectedEvent.whatsappPerson.name
                        : "none"
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="selectedOpen = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-dialog v-model="showImageDialog">
      <v-card>
        <v-img contain height="80vh" :src="imageDialogUrl"> </v-img>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eventDialog">
      <event-dialog  />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import EventDialog from './EventDialog.vue';
export default {
  name: "CalendarComponent",
  components: {EventDialog},
  data() {
    return {
      deleteConfirmdialog: false,
      showImageDialog: false,
      imageDialogUrl: "",
      value: "",
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      colors: [
        "#2196F3",
        "#3F51B5",
        "#673AB7",
        "#00BCD4",
        "#4CAF50",
        "#FF9800",
        "#757575",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
    };
  },
  props: {
    admin: {
      type: Boolean,
      default: true,
    },
    calendarStyle: {
      type: String,
      default: "height: 65vh",
    },
  },

  computed: {
    calendarDaysDisplay() {
      if (window.innerWidth > 768) {
        return "week"
      } else {
        return "4day"
      }
    },
    eventDialog: {
      get() {
        return this.$store.getters.eventDialog;
      },
      set(value) {
        this.$store.dispatch("setEventDialog", value);
      },
    },
    allEvents: {
      get() {
        return this.$store.getters.events;
      },
      set(value) {
        this.$store.dispatch("commitAllEvents", value);
      },
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  mounted() {
    console.log("Current time: ", moment().format());
    // this.getEvents();
  },
  methods: {
    isToday(date) {
      return moment(date, "DD").format("DD") === moment().format("DD");
    },
    formatEventTime(date) {
      console.log('Formatting date: ', date)
      var newDate;
      newDate = moment(date, "YYYY-MM-DD HH:mm").format("HH:mm")
      console.log('New time: ', newDate)
      return newDate
      
    },

    setToday() {
      console.log("Here one");
      this.value = "";
    },
    prev() {
      
      this.$refs.calendar.prev();
    },
    next() {
      
      this.$refs.calendar.next();
    },
    startDrag({ event, timed }) {
      console.log("Here 5");
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      console.log("Here 6");
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: this.rndElement(this.colors),
          start: this.createStart,
          end: this.createStart,
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      console.log("Here 7");
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      console.log("Here 8");
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      console.log("Here 9");
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      console.log("Here 10");
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      console.log("Here 11");
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      console.log("Here 12");
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    getEventColor(event) {
      console.log("Here 13");
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color;
    },
    getEvents({ start, end }) {
      console.log("Here 14");
      console.log("Start and en: ", start, end);
      console.log("All events: ", this.allEvents);
      const events = [];

      this.allEvents.forEach((event) => {
        const id = event.id;
        const name = event.name;
        const color = this.rndElement(this.colors);
        const startDate = moment(
          `${event.date} ${event.fromTime}`,
          "DD/MM/YYYY HH:mm"
        );
        console.log("Start date", startDate);
        const start = startDate.tz("Europe/Madrid").format("YYYY-MM-DD HH:mm");
        console.log("Start: ", start);
        const endDate = moment(
          `${event.date} ${event.toTime}`,
          "DD/MM/YYYY HH:mm"
        );
        const end = endDate.tz("Europe/Madrid").format("YYYY-MM-DD HH:mm");
        console.log("End: ", end);
        const timed = true;
        var imageUrl = null;

        if (event.originalUrls) {
          imageUrl = event.originalUrls[0].url;
        }

        events.push({
          id,
          name,
          description: event.description,
          color,
          start,
          end,
          timed,
          fromDate: event.date,
          fromTime: event.fromTime,
          toTime: event.toTime,
          type: event.type,
          registrationLink: event.registrationLink,
          venue: event.venue,
          meetingID: event.meetingID,
          meetingLink: event.meetingLink,
          whatsappPerson: event.whatsappPerson,
          imageUrl: imageUrl,
        });
      });

      this.events = events;
      console.log("Events: ", this.events);
    },
    showEventDialog({ nativeEvent, event }) {
      console.log("Event dialog: ", event);
      
      /* console.log("Event dialog: ", event);
      console.log("Native Event dialog: ", nativeEvent);
      this.eventDisplayed = event;
      console.log('Event displayed: ', this.eventDisplayed) */
      this.$store.dispatch("setEventDisplayed", event)
      this.$store.dispatch("setEventDialog", true)
    },
    eventClicked({ nativeEvent, event }) {
      if (this.admin) {
        this.showEventCard({ nativeEvent: nativeEvent, event: event });
      } else {
        this.showEventDialog({ nativeEvent: nativeEvent, event: event });
      }
    },
    showEventCard({ nativeEvent, event }) {
      console.log("Here 15");
      const open = () => {
        console.log("Event selected:", event);
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    deleteEvent(eventID) {
      console.log("Delete event");
      this.deleteConfirmdialog = false;
      this.$store.dispatch("deleteEvent", eventID);
    },
    getFormattedDate(date) {
      console.log("Date to be formatted: ", date);
      var newDate;
      if (date) {
        console.log("Here");
        newDate = moment(date, "DD/MM/YYYY").format("dddd, DD MMMM YYYY");
      } else {
        console.log("There");
      }

      return newDate;
    },
    showImage(imageUrl) {
      this.imageDialogUrl = imageUrl;
      this.showImageDialog = true;
    },
    intervalFormat(interval) {
      return interval.time;
    },
  },
};
</script>

<style scoped lang="scss">
.v-toolbar__title {
    margin-left: 16px !important;
}
@media screen and (max-width: 441px) {
  .v-toolbar__title {
    font-size: 0.75em;
    margin-left: 16px !important;
    white-space: normal;
}
}
@media screen and (max-width: 826px) and (min-width: 768px) {
 .v-toolbar__title {
    font-size: 1em;
    white-space: normal;
    margin-left: 16px !important;
}
}
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
